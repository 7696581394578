import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Icon from "../utilities/icons"
import { SideInfoBox } from "../components/styles/SideInfoBox"
import styled from "styled-components"
import SEO from "../components/seo"
import paragraphs from "lines-to-paragraphs"

const ReviewBlockWrapper = styled.div`
    display:flex;
    flex-direction:column;
`

const ReviewBlock = styled.div`
    background:#ffffff;
    border:1px solid #E2E8F0;
    border-radius:10px;
    margin-bottom:20px;
    border-bottom:2px solid ${props => props.theme.orange};

    .review-block-content {
        padding:10px 25px;
        
        @media (max-width:750px) {
            padding:0px 20px;
        }
    }

    h3 {
        font-size:1.1rem;
        font-weight: 600;
        color:${props => props.theme.orange};
    }

    p {
        font-size:0.9rem;
    }

    .reviewer-name {
        display:block;
        text-align:right;
        font-size:0.9rem;
    }

    .top_speech {
        padding:20px 20px 0 20px;
    }
    .bottom_speech {
        padding:0 20px 20px 20px;
        text-align:right;
    }
`;

const Reviews = props => {
    const reviews = props.data.reviews.edges

    console.log("reviews = ")
    console.log(reviews)

    return (
        <Layout>
            <SEO
                title="Reviews |  MJ Electrical | Electrician in Newark"
                description="Reviews for MJ Electrical - Electrician in Newark on Trent and surrounding areas. Covering Nottinghamshire, Lincolnshire, Leicestershire, Derbyshire"
            />
            <div className="standard-page-container">
                <div className="inner-container">
                    <h1>Reviews</h1>

                    <div className="two-column-layout-wrapper">
                        <div className="two-column-layout-item_wide ">

                            {reviews.map((review, i) => (
                                <ReviewBlock>
                                    <div className="top_speech">
                                        <Icon
                                            name="top_speech"
                                            color="#f05c28"
                                            width="18"
                                            height="18"
                                        />
                                    </div>
                                    <div className="review-block-content">
                                        <h3>{review.node.frontmatter.title}</h3>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: paragraphs(review.node.frontmatter.review_body),
                                            }}
                                        />
                                        <span class='reviewer-name'>{review.node.frontmatter.review_name}</span>
                                    </div>
                                    <div className="bottom_speech">
                                        <Icon
                                            name="bottom_speech"
                                            color="#f05c28"
                                            width="18"
                                            height="18"
                                        />
                                    </div>
                                </ReviewBlock>
                            ))}

                        </div>

                        <div className="two-column-layout-item_narrow">
                            <SideInfoBox className="global-box-shadow">
                                <h3 className="sidebox-title">Quick Contact</h3>
                                <ul className="contact-box-list">
                                    <li>
                                        <b>Phone / Text:</b> 07963 168251
                  </li>
                                    <li>
                                        <b>Email: </b>
                                        <a href="mailto:info@mjelectric.co.uk">
                                            {" "}
                      info@mjelectric.co.uk
                    </a>
                                    </li>
                                    {/*<li>
                    <b>Facebook: </b>
                    <a href="">
                      <Icon
                        name="facebook"
                        color="#3C5A99"
                        width="16"
                        height="16"
                      />
                    </a>
                  </li>*/}
                                </ul>
                            </SideInfoBox>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Reviews

export const query = graphql`
  query Reviews {
  reviews: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "data/reviews/.*.md$/"}}, sort: {order: ASC, fields: frontmatter___title}) {
    edges {
      node {
        id
        frontmatter {
          title
          review_body
          review_name
          is_featured
        }
      }
    }
  }
}
`
